import { forwardRef } from 'react'

import styled from 'styled-components'

// eslint-disable-next-line no-restricted-imports
import { Box } from '@cbhq/cds-web/layout/Box'
import type { BoxProps } from '@cbhq/cds-web/layout/Box'
import { palette } from '@cbhq/cds-web/tokens'

import type {
  ResponsiveStyleProps,
  StyleProps,
  StandardStyleProps,
  CustomStyleProps,
  StyleFunction,
} from './types'
import { breakpointKeys, mediaQueries } from '../../breakpoints'

export const pinStyles = {
  all: { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 },
  top: { position: 'absolute', top: 0, left: 0, right: 0 },
  bottom: { position: 'absolute', bottom: 0, left: 0, right: 0 },
  left: { position: 'absolute', top: 0, bottom: 0, left: 0 },
  right: { position: 'absolute', top: 0, bottom: 0, right: 0 },
} as const

type StylePropFunction = true | StyleFunction<any>

export const space = (value: number) => `calc(${value} * var(--spacing-1))`

export const color = (value: CustomStyleProps['color']) =>
  typeof value === 'object'
    ? `rgba(var(--${value[0]}), ${value[1]})`
    : (value as string) in palette
    ? palette[value as keyof typeof palette]
    : `rgb(var(--${value}))`

export const stylePropFunctions: {
  [key in keyof StyleProps]: key extends keyof StandardStyleProps
    ? true
    : key extends keyof CustomStyleProps
    ? StyleFunction<CustomStyleProps[key]>
    : never
} = {
  gridColumn: true,
  gridRow: true,
  gridAutoFlow: true,
  gridAutoColumns: true,
  gitAutoRows: true,
  gridTemplate: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  gridTemplateAreas: true,
  gridArea: true,

  zIndex: true,

  position: true,
  top: true,
  right: true,
  bottom: true,
  left: true,

  display: true,

  overflow: true,
  overflowX: true,
  overflowY: true,

  width: true,
  height: true,
  minWidth: true,
  minHeight: true,
  maxWidth: true,
  maxHeight: true,

  flex: true,
  flexWrap: true,
  flexDirection: true,
  flexGrow: true,
  flexShrink: true,
  flexBasis: true,
  alignItems: true,
  alignContent: true,
  alignSelf: true,
  justifyItems: true,
  justifyContent: true,
  justifySelf: true,
  order: true,
  columnCount: true,

  border: true,
  borderTop: true,
  borderBottom: true,
  borderLeft: true,
  borderRight: true,

  pin: (v) => pinStyles[v],

  // Handle colors from both Spectrum and the CDS palette
  color: (v) => ({ color: color(v) }),
  background: (v) => ({ backgroundColor: color(v) }),
  borderColor: (v) => ({ borderColor: color(v) }),

  gap: (v) => ({ gap: space(v) }),
  columnGap: (v) => ({ columnGap: space(v) }),
  rowGap: (v) => ({ rowGap: space(v) }),

  spacing: (v) => ({ padding: space(v) }),
  spacingTop: (v) => ({ paddingTop: space(v) }),
  spacingBottom: (v) => ({ paddingBottom: space(v) }),
  spacingStart: (v) => ({ paddingLeft: space(v) }),
  spacingEnd: (v) => ({ paddingRight: space(v) }),
  spacingVertical: (v) => ({ paddingTop: space(v), paddingBottom: space(v) }),
  spacingHorizontal: (v) => ({ paddingLeft: space(v), paddingRight: space(v) }),

  offset: (v) => ({ margin: space(-v) }),
  offsetTop: (v) => ({ marginTop: space(-v) }),
  offsetBottom: (v) => ({ marginBottom: space(-v) }),
  offsetStart: (v) => ({ marginLeft: space(-v) }),
  offsetEnd: (v) => ({ marginRight: space(-v) }),
  offsetVertical: (v) => ({ marginTop: space(-v), marginBottom: space(-v) }),
  offsetHorizontal: (v) => ({ marginLeft: space(-v), marginRight: space(-v) }),

  css: (v) => v,
}

export const mergeStyles = (a: any, b: any) => {
  const result = Object.assign({}, a, b)
  for (const key in a) {
    if (!a[key] || typeof b[key] !== 'object') continue
    Object.assign(result, {
      [key]: Object.assign(a[key], b[key]),
    })
  }
  return result
}

export const parseResponsiveStyle = (
  raw: unknown[] | { [key in (typeof breakpointKeys)[number]]?: unknown },
  styleFunction: StyleFunction<any>,
) => {
  const styles: Record<string, any> = {}
  let i = 0
  while (i < mediaQueries.length) {
    const value =
      'length' in raw ? (raw as unknown[])[i] : raw[breakpointKeys[i]]
    const media = mediaQueries[i]
    const style = value != null ? styleFunction(value) : null
    if (!media && style) Object.assign(styles, style)
    else if (media) styles[media] = style || {}
    i++
  }
  return styles
}

export const createStyleParser = (
  customStylePropFunctions?: Record<string, StylePropFunction>,
) => {
  return (componentProps: Record<string, any>) => {
    let styles = {}

    for (const propName in componentProps) {
      const raw = componentProps[propName]
      if (raw == null) continue
      let styleFunction =
        stylePropFunctions[propName as unknown as keyof StyleProps]
      if (!styleFunction && customStylePropFunctions)
        styleFunction = customStylePropFunctions[propName]
      if (!styleFunction) continue
      if (styleFunction === true)
        styleFunction = (v: any) => ({ [propName]: v })

      if (typeof raw === 'object' && propName !== 'css') {
        styles = mergeStyles(styles, parseResponsiveStyle(raw, styleFunction))
        continue
      }

      Object.assign(styles, (styleFunction as any)(raw))
    }

    return styles
  }
}

export const styleFunction = createStyleParser()

const ClassNameBox = forwardRef(
  (
    props: { className?: string } & BoxProps,
    ref: React.ForwardedRef<HTMLElement>,
  ) => <Box {...props} ref={ref} className={props?.className} />,
)

ClassNameBox.displayName = 'ClassNameBox'

export type DivProps = ResponsiveStyleProps &
  Omit<BoxProps, keyof ResponsiveStyleProps | 'as'> &
  Omit<JSX.IntrinsicAttributes, 'css'> &
  React.RefAttributes<HTMLElement> & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
    className?: string
  }

export const Div = styled(ClassNameBox).withConfig({
  shouldForwardProp: (prop) => !Boolean((stylePropFunctions as any)[prop]),
})<ResponsiveStyleProps>((props) => {
  const styles = styleFunction(props) as any
  if (!Boolean(styles.display)) styles.display = 'flex'
  return styles
}) as React.FunctionComponent<React.PropsWithChildren<DivProps>>
