import type { AppConfig } from './types'

let nextConfig: AppConfig['nextConfig'] = {
  // When a page fails for some reason, it becomes an error page - so we set error pages
  // to a short revalidate time (5 seconds) so we can retry soon. Normal pages are set to
  // a longer revalidate time (60 seconds).
  revalidateTimeErrorPages: 5,
  revalidateTime: 60,
  buildStatics: true,
  buildPreviewContent: false,
  basePath: '',
}

let contentfulConfig: AppConfig['contentfulConfig'] = {
  space: '',
  accessToken: '',
  host: '',
  environment: '',
  retryLimit: undefined,
  appTagId: '',
}

let wasInitialized = false

export const initAppConfig = (config: AppConfig) => {
  nextConfig = config.nextConfig
  contentfulConfig = config.contentfulConfig
  wasInitialized = true
}

export const getAppConfig = (): AppConfig => {
  if (!wasInitialized)
    throw Error('Must call initAppConfig before getAppConfig')
  return { nextConfig, contentfulConfig }
}
