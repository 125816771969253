// eslint-disable-next-line no-restricted-imports
import { Link as CDSLink } from '@cbhq/cds-web/typography/Link'
// eslint-disable-next-line no-restricted-imports
import type { LinkProps as CDSLinkProps } from '@cbhq/cds-web/typography/Link'

import { useIsExternalUrl } from './useIsExternalUrl'
import { useAnalyticsContext } from '../../providers/AnalyticsProvider'
import { useLinkComponent } from '../../providers/LinkComponentProvider'
import { useLocaleContext } from '../../providers/LocaleProvider'
import { cleanUrlDuplicateSlashes } from '../../utils/cleanUrlDuplicateSlashes'
import { getIsAnchorLink } from '../../utils/getIsAnchorLink'
import { getIsRelativeUrl } from '../../utils/getIsRelativeUrl'
import { getLocalizedUrl } from '../../utils/getLocalizedUrl'
import { scrollToAnchor } from '../../utils/scrollToAnchor'

export type LinkComponent = (props: {
  children: React.ReactNode
  href: string
}) => React.ReactElement

type LinkProps = {
  children: React.ReactNode
  href?: string
  prefetch?: boolean
  'data-qa'?: string
  className?: string
  underline?: boolean
} & Omit<CDSLinkProps, 'to'>

export const Link = ({
  children,
  href = '',
  'data-qa': dataQa = '',
  className,
  underline = false,
  ...props
}: LinkProps) => {
  const cleanHref = cleanUrlDuplicateSlashes(href)
  const isCtaPhone = href.startsWith('tel')
  const isMailtoCta = href.startsWith('mailto')
  const isRelativeUrl = getIsRelativeUrl(cleanHref)
  const isAnchorLink = getIsAnchorLink(cleanHref)
  const isExternalUrl = useIsExternalUrl(cleanHref)

  const { locale } = useLocaleContext()
  const LinkComponent = useLinkComponent()
  const { analyticsPrefix } = useAnalyticsContext()

  const localizedUrl = isRelativeUrl
    ? cleanHref
    : getLocalizedUrl(cleanHref, locale)

  const finalDataQa = `${analyticsPrefix}${
    dataQa && analyticsPrefix ? '-' : ''
  }${dataQa}`

  const onAnchorLinkPress = (event: React.MouseEvent) => {
    event.preventDefault()
    scrollToAnchor(cleanHref.replace('#', ''))
  }

  if (!!LinkComponent && isRelativeUrl && !isCtaPhone && !isMailtoCta) {
    return (
      <LinkComponent href={cleanHref} data-qa={finalDataQa}>
        <CDSLink
          {...props}
          underline={underline}
          className={className}
          data-qa={finalDataQa}
        >
          {children}
        </CDSLink>
      </LinkComponent>
    )
  }
  return (
    <CDSLink
      {...props}
      underline={underline}
      href={isCtaPhone || isMailtoCta ? href : localizedUrl}
      data-qa={finalDataQa}
      openInNewWindow={isExternalUrl}
      className={className}
      rel={isExternalUrl ? 'noopener noreferrer' : undefined}
      onPress={isAnchorLink ? onAnchorLinkPress : undefined}
    >
      {children}
    </CDSLink>
  )
}
