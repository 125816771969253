// Contentful locales as of 2024-jun-14
const contentfulLocales = [
  'en',
  'zh-cn',
  'zh-tw',
  'zh-sg',
  'Hant-TW',
  'nl',
  'en-au',
  'en-AU',
  'en-br',
  'en-ca',
  'en-CA',
  'en-fr',
  'en-de',
  'en-pt',
  'en-sg',
  'en-SG',
  'en-es',
  'en-gb',
  'en-GB',
  'en-tr',
  'en-nl',
  'en-it',
  'fr',
  'fr-ca',
  'fr-fr',
  'fr-FR',
  'de',
  'de-de',
  'de-DE',
  'id',
  'it',
  'ja',
  'ko',
  'pl',
  'pt',
  'pt-br',
  'pt-BR',
  'pt-pt',
  'pt-PT',
  'ru',
  'es',
  'es-la',
  'es-419',
  'es-es',
  'es-ES',
  'es-us',
  'es-US',
  'th',
  'tr',
]

module.exports = { contentfulLocales }
