import { useCallback } from 'react'

import { IntlProvider } from 'react-intl'

import { ThemeProvider as CDSThemeProvider } from '@cbhq/cds-web/system/ThemeProvider'
import type { ThemeProviderProps } from '@cbhq/cds-web/system/ThemeProvider'

import { CanonicalUrlProvider } from './CanonicalUrlProvider'
import type { CanonicalUrlProviderProps } from './CanonicalUrlProvider'
import { DeploymentTargetProvider } from './DeploymentTargetProvider'
import { ImageComponentProvider } from './ImageComponentProvider'
import type { ImageComponentProviderProps } from './ImageComponentProvider'
import { LinkComponentProvider } from './LinkComponentProvider'
import type { LinkComponentProviderProps } from './LinkComponentProvider'
import { LocaleProvider } from './LocaleProvider'
import type { LocaleProviderProps } from './LocaleProvider'
import { LoggerProvider, useLogger } from './LoggerProvider'
import type { LoggerProviderProps } from './LoggerProvider'
import { MicrocopyProvider } from './MicrocopyProvider'
import type { MicrocopyProviderProps } from './MicrocopyProvider'
import { SectionSpacingProvider } from './SectionSpacingProvider'
import type { SectionSpacingProviderProps } from './SectionSpacingProvider'

type ComponentsProviderProps = LoggerProviderProps &
  ThemeProviderProps &
  LinkComponentProviderProps &
  SectionSpacingProviderProps &
  ImageComponentProviderProps &
  CanonicalUrlProviderProps &
  MicrocopyProviderProps &
  SectionSpacingProviderProps &
  LocaleProviderProps & { messages?: Record<string, string> } & {
    deploymentTargetName?: string
  }

const CDSThemeProviderWithChildren =
  CDSThemeProvider as React.FunctionComponent<
    React.PropsWithChildren<ThemeProviderProps>
  >

export const ComponentsProvider = ({
  canonicalUrl,
  children,
  logger,
  spectrum,
  linkComponent,
  imageComponent,
  microcopies,
  locale,
  localeFromUrl,
  defaultLoggedOutConfig,
  fallbackLoggedOutConfig,
  deploymentTargetName,
  messages,
  ...sectionSpacingProps
}: ComponentsProviderProps) => {
  const { notifyError } = useLogger()

  /*
   * This is very noisy so only sending 5% of the events.
   */
  const onError = useCallback(
    (err: any) => {
      if (Math.random() * 100 < 5 && err.code !== 'MISSING_TRANSLATION') {
        notifyError(err?.code)
      }
    },
    [notifyError],
  )

  return (
    <IntlProvider messages={messages || {}} locale={locale} onError={onError}>
      <DeploymentTargetProvider
        deploymentTargetName={deploymentTargetName || 'development'}
      >
        <LocaleProvider
          locale={locale}
          localeFromUrl={localeFromUrl}
          defaultLoggedOutConfig={defaultLoggedOutConfig}
          fallbackLoggedOutConfig={fallbackLoggedOutConfig}
        >
          <LoggerProvider logger={logger}>
            <MicrocopyProvider microcopies={microcopies}>
              <CanonicalUrlProvider canonicalUrl={canonicalUrl}>
                <SectionSpacingProvider {...sectionSpacingProps}>
                  <LinkComponentProvider linkComponent={linkComponent}>
                    <ImageComponentProvider imageComponent={imageComponent}>
                      <CDSThemeProviderWithChildren spectrum={spectrum}>
                        {children}
                      </CDSThemeProviderWithChildren>
                    </ImageComponentProvider>
                  </LinkComponentProvider>
                </SectionSpacingProvider>
              </CanonicalUrlProvider>
            </MicrocopyProvider>
          </LoggerProvider>
        </LocaleProvider>
      </DeploymentTargetProvider>
    </IntlProvider>
  )
}
