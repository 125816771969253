import { createContext, useContext } from 'react'

type AnalyticsProviderContext = {
  analyticsPrefix: string
}
const AnalyticsContext = createContext<AnalyticsProviderContext>({
  analyticsPrefix: '',
})

export type AnalyticsProviderProps = {
  children: React.ReactNode
} & AnalyticsProviderContext

export const useAnalyticsContext = () => {
  return useContext(AnalyticsContext)
}

export const AnalyticsProvider = ({
  children,
  analyticsPrefix,
}: AnalyticsProviderProps) => {
  const { analyticsPrefix: parentAnalyticsPrefix = '' } = useAnalyticsContext()
  return (
    <AnalyticsContext.Provider
      value={{
        analyticsPrefix: `${parentAnalyticsPrefix}${
          parentAnalyticsPrefix && analyticsPrefix ? '-' : ''
        }${analyticsPrefix}`,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
