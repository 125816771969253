const { getSupportedLocalesFromConfig } = require('@cbhq/intl')
const { notifyError } = require('../utils/logs')
const { areLocalesSupported, formatSupportedLocales } = require('./utils')

const supportedLocalesFromConfig = getSupportedLocalesFromConfig()
// This is the format that Contentful expects otherwise it will fail to fetch the content. Can be removed once Contentful is updated.
const formattedLocales = formatSupportedLocales(supportedLocalesFromConfig)
let supportedLocales = formattedLocales

if (!areLocalesSupported(formattedLocales)) {
  notifyError(
    'Contentful does not support the locales provided in the config. Falling back to English.',
  )
  supportedLocales = ['en']
}

module.exports = supportedLocales
