import { COINBASE_DOMAIN, WWW_COINBASE_DOMAIN } from '../constants'

export const getLocalizedUrl = (url: string, locale: string = 'en') => {
  if (!locale || locale === 'en' || url.includes('oauth_signup')) return url

  // Ensure URL is not an anchor, mailto, tel, etc.
  if (
    url.startsWith('#') ||
    url.startsWith('mailto:') ||
    url.startsWith('tel:') ||
    !url.match(/^https?:\/\//)
  ) {
    return url
  }

  const parsedUrl = new URL(url)

  if (
    parsedUrl.hostname === COINBASE_DOMAIN ||
    parsedUrl.hostname === WWW_COINBASE_DOMAIN
  ) {
    const newPathname = `/${locale}${parsedUrl.pathname}`
    parsedUrl.pathname = newPathname
  }

  return parsedUrl.toString()
}
