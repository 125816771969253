import { useState, useEffect } from 'react'

import NextLink from 'next/link'
import { useRouter } from 'next/router'

import type { LinkComponent } from '@cbhq/cdx-components'

const removeHrefBasePath = (href: string) => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH || ''
  if (href === basePath) return '/'
  if (!href.startsWith(basePath)) return href
  return href.slice(basePath.length)
}

export const Link: LinkComponent = ({ children, href }) => {
  const [fallbackHref, setFallbackHref] = useState('')
  const { locale } = useRouter()

  useEffect(() => {
    if (typeof window !== 'undefined' && !href)
      setFallbackHref(window?.location.pathname)
  }, [href])

  const hrefWithoutBasePath = removeHrefBasePath(href)

  return (
    <NextLink
      href={hrefWithoutBasePath || fallbackHref}
      passHref
      legacyBehavior
      locale={locale}
      prefetch={false}
    >
      {children}
    </NextLink>
  )
}

export const LinkShallow: LinkComponent = ({ children, href }) => {
  const [fallbackHref, setFallbackHref] = useState('')
  useEffect(() => {
    if (typeof window !== 'undefined' && !href)
      setFallbackHref(window?.location.pathname)
  }, [href])

  return (
    <NextLink href={href || fallbackHref} passHref shallow legacyBehavior>
      {children}
    </NextLink>
  )
}
