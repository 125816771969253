import { createContext, useContext } from 'react'

import type { LinkComponent } from '../ui/Link'

export type LinkComponentProviderProps = {
  children: React.ReactNode
  linkComponent?: LinkComponent
}

const DefaultLinkComponent: LinkComponent = (props) => <a {...props} />

const LinkComponentContext = createContext<LinkComponent>(DefaultLinkComponent)

export const LinkComponentProvider = ({
  children,
  linkComponent = DefaultLinkComponent,
}: LinkComponentProviderProps) => (
  <LinkComponentContext.Provider value={linkComponent}>
    {children}
  </LinkComponentContext.Provider>
)

export const useLinkComponent = () => useContext(LinkComponentContext)
