export const legacyButtonRadius = 8
export const COINBASE_DOMAIN = 'coinbase.com'
export const WWW_COINBASE_DOMAIN = 'www.coinbase.com'

// every instance of a link/button needs one of these
export const ANALYTICS_KEY = {
  AvatarCard: 'AvatarCard',
  AccordionRichText: 'AccordionRichText',
  ArticleRichText: 'ArticleRichText',
  Asset: 'Asset',
  Banner: 'Banner',
  ButtonLabel: 'ButtonLabel',
  CardEyebrow: 'CardEyebrow',
  CardGrid: 'CardGrid',
  CardHeader: 'CardHeader',
  CardIllustration: 'CardIllustration',
  CardImage: 'CardImage',
  CardResourceGrid: 'CardResourceGrid',
  Category: 'Category',
  CategoryTag: 'CategoryTag',
  Child: 'Child',
  Column: 'Column',
  ComparisonCardCTA: 'ComparisonCardCTA',
  Content: 'Content',
  CTAButtonsFirst: 'CTAButtonsFirst',
  CTAButtonsSecond: 'CTAButtonsSecond',
  EditorialRichText: 'EditorialRichText',
  Facebook: 'Facebook',
  HelpText: 'HelpText',
  HighlightAsset: 'HighlightAsset',
  LabelRichText: 'LabelRichText',
  LegalRichText: 'LegalRichText',
  LinkArrow: 'LinkArrow',
  LinkChain: 'LinkChain',
  LinkedIn: 'LinkedIn',
  MainLogo: 'MainLogo',
  MainLogoHelpCenter: 'MainLogoHelpCenter',
  MobileNavContactUs: 'MobileNavContactUs',
  MobileNavPrimary: 'MobileNavPrimary',
  MobileNavSecondary: 'MobileNavSecondary',
  MobileTrapDoorLink: 'MobileTrapDoorLink',
  Navlink: 'Navlink',
  NavLinkButton: 'NavLinkButton',
  NavLinkStyled: 'NavLinkStyled',
  PillTag: 'PillTag',
  PrimaryCtaLabel: 'PrimaryCtaLabel',
  ProductName: 'ProductName',
  Row: 'Row',
  SearchTag: 'SearchTag',
  SecondaryCtaLabel: 'SecondaryCtaLabel',
  SecondaryImage: 'SecondaryImage',
  SeeAllProducts: 'SeeAllProducts',
  Select: 'select',
  SharedRichText: 'SharedRichText',
  SocialMedia: 'SocialMedia',
  Submit: 'Submit',
  SubNavLink: 'SubNavLink',
  SvgContent: 'SvgContent',
  SvgLink: 'SvgLink',
  TrapDoorLabel: 'TrapDoorLabel',
  TrapdoorLinks: 'TrapdoorLinks',
  TrapDoorProductListColumn: 'TrapDoorProductListColumn',
  Thumbnail: 'Thumbnail',
  Twitter: 'Twitter',
  TwoColumnContainerLeft: 'TwoColumnContainerLeft',
  TwoColumnContainerRight: 'TwoColumnContainerRight',
}
