import { createContext, useContext } from 'react'

const DeploymentTargetContext = createContext<
  Omit<DeploymentTargetProviderProps, 'children'>
>({
  deploymentTargetName: 'development',
})

export type DeploymentTargetProviderProps = {
  deploymentTargetName: string
  children: React.ReactNode
}

export const DeploymentTargetProvider = ({
  deploymentTargetName,
  children,
}: DeploymentTargetProviderProps) => (
  <DeploymentTargetContext.Provider
    value={{
      deploymentTargetName,
    }}
  >
    {children}
  </DeploymentTargetContext.Provider>
)

export const useDeploymentTargetContext = () =>
  useContext(DeploymentTargetContext)
