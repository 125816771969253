// Prior art https://github.com/andy-piccalilli/modern-css-reset

import { createGlobalStyle } from 'styled-components'

export const CSSReset = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
  }

  body {
    height: 100%;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    text-rendering: optimizeLegibility;
  }

  [id] {
    scroll-margin-top: 15ex;
  }

  iframe {
    border: 0;
  }

  img,
  picture {
    max-width: 100%;
    display: block;
  }

  img[width][height] {
    height: auto;
  }

  ul {
    list-style: none;
  }

  a,
  button {
    text-decoration: none;
  }

  button,
  select {
    cursor: pointer;
    &:disabled {
      cursor: auto;
    }
  }

  a,
  button,
  input,
  textarea,
  select,
  address {
    font: inherit;
    outline: none;

    &:focus-visible {
      outline: 2px auto #005fd7;
      outline: 2px auto -webkit-focus-ring-color;
    }
  }
`
