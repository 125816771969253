import type { ComposePageFields } from '@cbhq/cdx-contentful'
import { getEntryProps } from '@cbhq/cdx-contentful'
import type { SEOProps } from '@cbhq/cdx-next'
import { SEO as SEOComponent } from '@cbhq/cdx-next'

export type SEOContentfulProps = {
  page?: ComposePageFields<any>
  seoDefaultProps?: SEOProps
  titleSuffix?: string
}

export const SEO = ({
  page,
  seoDefaultProps,
  titleSuffix = '',
}: SEOContentfulProps) => {
  const {
    title,
    description,
    image,
    // TO DO: fix these in Contentful to use camelCase
    no_follow: noFollow,
    no_index: noIndex,
    twitterCard,
  } = getEntryProps(page?.seo)

  const titleSeo = title || seoDefaultProps?.title || ''
  const titleDisplay = `${titleSeo}${titleSuffix ?? ''}`

  const descriptionSeo = description || seoDefaultProps?.description

  const imageUrl = image?.fields?.file?.url || seoDefaultProps?.imageUrl
  const imageUrlWithProtocol = imageUrl && `https:${imageUrl}`

  const googleSiteVerification =
    page?.content?.fields?.projectConfig?.fields
      ?.googleSearchConsoleVerificationTag

  return (
    <SEOComponent
      title={titleDisplay}
      description={descriptionSeo}
      imageUrl={imageUrlWithProtocol}
      noFollow={noFollow}
      noIndex={noIndex}
      twitterCard={twitterCard}
      googleSiteVerification={googleSiteVerification}
    />
  )
}
