export const NAVBAR_LOGO_HEIGHT = 20
export const NAVBAR_LOGO_WIDTH = 112
export const NAVBAR_NAV_HEIGHT = 59
export const NAVBAR_MAX_WIDTH = 1228
export const NAVBAR_SUB_NAV_HEIGHT = 52
export const NAVBAR_BANNER_HEIGHT = 32
export const NAVBAR_SECONDARY_LOGO_WIDTH = 150
export const SUBNAV_CONTAINER_Z_INDEX = 1
export const GLOBAL_HEADER_CONTAINER_Z_INDEX = 10
export const GLOBAL_HEADER_HEIGHT = 112
export const GLOBAL_HEADER_BREAKPOINT_DESKTOP = 1050
export const BANNER_HEIGHT_45 = 45
export const BANNER_HEIGHT_0 = 0
export const UK_BANNER_HEIGHT = 120
