const { contentfulLocales } = require('./contentfulLocales')

function areLocalesSupported(
  configLocales,
  contentfullLocales = contentfulLocales,
) {
  if (contentfullLocales.length < configLocales.length) {
    return false
  }

  return configLocales.every((element) => contentfullLocales.includes(element))
}

function formatSupportedLocales(supportedLocales) {
  return Array.from(new Set([...supportedLocales, 'en']))
}

module.exports = { areLocalesSupported, formatSupportedLocales }
