import {
  init as initClientAnalytics,
  PlatformName,
  initNextJsTrackPageview,
  logEvent,
  logMetric,
  ActionType,
  MetricType,
  identify,
  ComponentType,
} from '@cbhq/client-analytics'

import { cdxAnalyticsEventTarget } from './cdxAnalyticsEventTarget'
import { LOGGING_IDS } from './constants'
import { getAnalyticsDefaultProps } from './getAnalyticsDefaultProps'
import { getCoinbaseDeviceId } from './getCoinbaseDeviceId'
import { trackClicks } from './trackClicks'
import { trackScrollThresholds } from './trackScrollThresholds'
import type { CDXAnalyticsLoadedEventDetail, InitConfig } from './types'

export function initTracking({
  getAnalyticsProps,
  clientAnalyticsConfig,
}: InitConfig) {
  const shouldTrackClientAnalytics = !!clientAnalyticsConfig

  const getProps = getAnalyticsProps
    ? () => getAnalyticsProps(getAnalyticsDefaultProps())
    : () => getAnalyticsDefaultProps()

  // initialize client-analytics client
  if (shouldTrackClientAnalytics) {
    const { isProd, projectName, nextJsRouter, pageKeyRegex, amplitudeApiKey } =
      clientAnalyticsConfig
    initClientAnalytics({
      isProd,
      platform: PlatformName.web,
      projectName,
      showDebugLogging: !isProd,
      amplitudeApiKey,
    })
    const deviceId = getCoinbaseDeviceId()
    if (deviceId && amplitudeApiKey) {
      identify({ deviceId })
    }
    if (nextJsRouter)
      initNextJsTrackPageview({
        pageKeyRegex,
        nextJsRouter,
      })
  }

  // attach event handlers
  const removeScrollEffects = trackScrollThresholds({
    getProps,
  })
  const removeClickEffects = trackClicks({
    getProps,
    shouldTrackClientAnalytics,
  })

  const clientAnalyticsLoadedEvent =
    new CustomEvent<CDXAnalyticsLoadedEventDetail>('client-analytics-loaded', {
      detail: {
        logEvent,
        logMetric,
        ActionType,
        ComponentType,
        MetricType,
        LOGGING_IDS,
      },
    })

  cdxAnalyticsEventTarget.dispatchEvent(clientAnalyticsLoadedEvent)
  // cleanup
  return () => {
    removeClickEffects()
    removeScrollEffects()
  }
}
