import { createContext, useContext } from 'react'

const LocaleContext = createContext<Omit<LocaleProviderProps, 'children'>>({
  locale: 'en',
  localeFromUrl: '',
  defaultLoggedOutConfig: {},
  fallbackLoggedOutConfig: [],
})

export type LocaleProviderProps = {
  children: React.ReactNode
  locale: string
  localeFromUrl: string
  defaultLoggedOutConfig?: Record<string, any>
  fallbackLoggedOutConfig?: string[]
}

export const LocaleProvider = ({
  children,
  locale = 'en',
  localeFromUrl,
  defaultLoggedOutConfig = {},
  fallbackLoggedOutConfig,
}: LocaleProviderProps) => (
  <LocaleContext.Provider
    value={{
      locale,
      localeFromUrl,
      defaultLoggedOutConfig,
      fallbackLoggedOutConfig,
    }}
  >
    {children}
  </LocaleContext.Provider>
)

export const useLocaleContext = () => useContext(LocaleContext)
