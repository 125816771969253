const thresholdEvents: Record<string, number> = {
  '25%_threshold': 0.25,
  '50%_threshold': 0.5,
  '75%_threshold': 0.75,
  '100%_threshold': 1,
}

export function trackScrollThresholds({
  getProps,
}: {
  getProps: () => Record<string, any>
}) {
  const body = document.querySelector('body')
  // create parent element to mirror body since body is not guaranteed to be position relative
  const parent = document.createElement('div')

  parent.id = 'scroll-thresholds'
  parent.style.position = 'absolute'
  parent.style.pointerEvents = 'none'
  parent.style.top = '0'
  parent.style.height = `${body?.offsetHeight}px`
  parent.style.width = '100%'
  // create and add thresholds elements to parent
  const thresholdElements = Object.keys(thresholdEvents).map((id) =>
    createThreshold(id, `${thresholdEvents[id] * 100}%`),
  )
  thresholdElements.forEach((element) => parent.appendChild(element))
  body?.prepend(parent)

  const bodyHeightObserver = new ResizeObserver(
    (entries) => (parent.style.height = `${entries[0].target.clientHeight}px`),
  )
  bodyHeightObserver.observe(document.body)

  const thresholdObserver = new IntersectionObserver(
    (entries, _observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id in thresholdEvents) {
          _observer.unobserve(entry.target)
          /* TODO: EMKTG-6391 what we can replace segment with for scroll depth report (potentially amplitude)
          segmentAnalytics?.track('depth scrolled', {
            ...getProps(),
            ...getUrlProps(),
            depthPercentage: thresholdEvents[entry.target.id],
          })
         */
        }
      })
    },
    {
      threshold: 1,
    },
  )
  // observe threshold elements
  if (body)
    thresholdElements.forEach((element) => thresholdObserver.observe(element))

  // return function for removing effects
  return () => {
    thresholdObserver.disconnect()
    bodyHeightObserver.disconnect()
    parent.remove()
  }
}

function createThreshold(id: string, top: `${number}%`) {
  const thresholdElement = document.createElement('div')
  thresholdElement.id = id
  thresholdElement.style.position = 'absolute'
  thresholdElement.style.height = '0px'
  thresholdElement.style.width = '100%'
  thresholdElement.style.top = top
  return thresholdElement
}
