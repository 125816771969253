import { App } from ':cbam/components/App'
import {
  CONTENTFUL_CONFIGS,
  CONTENTFUL_CONFIGS_PREVIEW,
  NEXT_CONFIGS,
} from ':cbam/constants'
import { reportWebVitals } from '@cbhq/cdx-analytics'
import { initContentfulClient } from '@cbhq/cdx-contentful'
import { initAppConfig } from '@cbhq/cdx-next-contentful'

import '@cbhq/cds-web/globalStyles'

export { reportWebVitals }

initAppConfig({
  nextConfig: NEXT_CONFIGS,
  contentfulConfig: CONTENTFUL_CONFIGS,
})

initContentfulClient({
  config: CONTENTFUL_CONFIGS,
  previewConfig: CONTENTFUL_CONFIGS_PREVIEW,
})

export default App
