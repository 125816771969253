import type { NextWebVitalsMetric } from 'next/app'

/** Maps NextWebVitalsMetrics keys to @cbhq/client-analytics logMetric metricNames */
export const nextjsMetricKeyMap = {
  'Next.js-hydration': 'perf_nextjs_hydration',
  'Next.js-route-change-to-render': 'perf_nextjs_route_change_to_render',
  'Next.js-render': 'perf_nextjs_render',
}

export const getNextjsMetricName = (metric: NextWebVitalsMetric) =>
  metric.label === 'custom' && nextjsMetricKeyMap[metric.name]
