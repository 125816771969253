import Link from 'next/link'
import { useRouter } from 'next/router'

import { Link as CDXLink, Div, Text } from '@cbhq/cdx-components'

import type { PreviewDisplayProps } from '../types'

// TO DO: remove this from the bundle in prod environments?
export const PreviewDisplay = ({
  env,
  space,
  entry,
  canLeave,
}: PreviewDisplayProps) => {
  const { asPath } = useRouter()

  if (!env || !space || !entry) return null

  const entryUrl = `https://app.contentful.com/spaces/${space}/environments/${env}/entries/${entry}`

  return (
    <Div
      position="fixed"
      background="backgroundAlternate"
      bottom={8}
      left={8}
      zIndex={1000}
      elevation={2}
    >
      <Div flexDirection="column" spacing={1}>
        <Text variant="label1" as="p">
          Preview mode{' '}
        </Text>
        <Text variant="label2" as="p" spacingTop={1}>
          Space: {space}
        </Text>
        <Text variant="label2" as="p">
          Env: {env}
        </Text>

        <Text variant="label2" as="p">
          Entry:{' '}
          <CDXLink href={entryUrl} target="_blank">
            {entry}
          </CDXLink>
        </Text>

        {canLeave && (
          <Text variant="label2" as="p" spacingTop={1}>
            <Link href={`/api/leave-preview?slug=${asPath}`} prefetch={false}>
              Leave preview mode
            </Link>
          </Text>
        )}
      </Div>
    </Div>
  )
}
