import { createContext, useContext } from 'react'

const CanonicalUrlContext = createContext<string>('')

export type CanonicalUrlProviderProps = {
  children: React.ReactNode
  canonicalUrl?: string
}

export const CanonicalUrlProvider = ({
  children,
  canonicalUrl = '',
}: CanonicalUrlProviderProps) => (
  <CanonicalUrlContext.Provider value={canonicalUrl}>
    {children}
  </CanonicalUrlContext.Provider>
)

export const useCanonicalUrl = () => useContext(CanonicalUrlContext)
