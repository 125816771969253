import type { ErrorInfo } from 'react'
import { Component, createContext, useContext } from 'react'

export type DefaultErrorBoundaryProps = React.PropsWithChildren<{
  fallback?: React.ReactNode
  onError?: (error: Error, errorInfo: ErrorInfo) => void
}>

export type ErrorBoundaryComponent =
  React.ComponentType<DefaultErrorBoundaryProps>
export class DefaultErrorBoundary extends Component<
  DefaultErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: DefaultErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError?.(error, errorInfo)
  }

  render() {
    if (this.state.hasError) return this.props.fallback || null
    return this.props.children
  }
}

export const ErrorBoundaryContext =
  createContext<ErrorBoundaryComponent>(DefaultErrorBoundary)

export type ErrorBoundaryProviderProps = React.PropsWithChildren<{
  errorBoundaryComponent: ErrorBoundaryComponent
}>

export const ErrorBoundaryProvider = ({
  errorBoundaryComponent,
  children,
}: ErrorBoundaryProviderProps) => (
  <ErrorBoundaryContext.Provider value={errorBoundaryComponent}>
    {children}
  </ErrorBoundaryContext.Provider>
)

export const useErrorBoundary = () => useContext(ErrorBoundaryContext)
