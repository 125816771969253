export const scrollToAnchor = (id: string, offset = 0) => {
  const element = document.getElementById(id)

  if (element) {
    const heightToDiv =
      element?.getBoundingClientRect().top - offset + window.scrollY

    window.scroll({
      top: heightToDiv,
      behavior: 'smooth',
    })
  }
}
