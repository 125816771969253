type LogLevel = 'debug' | 'info' | 'warn' | 'error'

type LogMessage = {
  level: LogLevel
  message: string
  timestamp: string
  context?: string
  error?: any
}

function formatLogMessage(
  level: LogLevel,
  message: string,
  context?: string,
  error?: any,
): LogMessage {
  return {
    level,
    message,
    timestamp: new Date().toISOString(),
    ...(context && { context }),
    ...(error && { error: error.stack || error.toString() }),
  }
}

export function logDebug(message: string, context?: string) {
  console.debug(JSON.stringify(formatLogMessage('debug', message, context)))
}

export function logInfo(message: string, context?: string) {
  console.log(JSON.stringify(formatLogMessage('info', message, context)))
}

export function notifyError(message: string, context?: string) {
  console.warn(JSON.stringify(formatLogMessage('warn', message, context)))
}

export function logError(message: string, error?: any, context?: string) {
  console.error(
    JSON.stringify(formatLogMessage('error', message, context, error)),
  )
}
