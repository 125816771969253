import { createContext, useContext, useMemo } from 'react'

export type MicrocopyContextKeys = 'global' | 'blog' | 'cloud'

type MicrocopyContextData = Record<
  MicrocopyContextKeys,
  Record<string, string> | undefined
>

const defaultValues = {
  global: {
    readTime: 'read time',
    videoDuration: '',
    author: 'Author',
    by: 'By',
    search: 'Search',
    suggestedTopics: 'Suggested Topics',
    searchForTopicOrArticle: 'Search for a topic or article',
    subscribed: 'You’re subscribed!',
    submit: 'Submit',
    emailPlaceholder: 'Enter Email',
    sortBy: 'Sort by',
    newest: 'Newest',
    oldest: 'Oldest',
    article: 'article',
    articles: 'articles',
    availableFor: 'Available For',
    protocols: 'Protocols',
  },
  blog: {},
  cloud: {},
}

export type MicrocopyGlobalValues = keyof (typeof defaultValues)['global']

const MicrocopyContext = createContext<MicrocopyContextData>(defaultValues)

export type MicrocopyProviderProps = {
  children: React.ReactNode
  microcopies?: MicrocopyContextData
}

export const MicrocopyProvider = ({
  children,
  microcopies,
}: MicrocopyProviderProps) => {
  const values = useMemo(() => {
    if (!microcopies) return

    return Object.keys(microcopies).reduce<MicrocopyContextData>(
      (acc, key) => {
        const defiendGroup = microcopies[key as MicrocopyContextKeys]
        const defaultGroup = defaultValues[key as MicrocopyContextKeys]

        return { ...acc, [key]: { ...defaultGroup, ...defiendGroup } }
      },
      { global: undefined, blog: undefined, cloud: undefined },
    )
  }, [microcopies])

  return (
    <MicrocopyContext.Provider value={values || defaultValues}>
      {children}
    </MicrocopyContext.Provider>
  )
}

export const useMicrocopyContext = <T extends MicrocopyContextData>(): T =>
  useContext(MicrocopyContext) as T

export const useGlobalMicrocopy = () =>
  useMicrocopyContext<typeof defaultValues>()
