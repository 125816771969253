import { PHASE_PRODUCTION_BUILD } from 'next/constants'

import type { AppConfig } from '@cbhq/cdx-next-contentful'

export const DEPLOYMENT_TARGET_NAME =
  process.env.ENVIRONMENT_NAME || process.env.NEXT_PUBLIC_ENVIRONMENT_NAME

export const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH ?? ''

let rateLimitErrorRetryLimit = undefined

if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
  rateLimitErrorRetryLimit = Number(
    process.env.NEXT_PUBLIC_CONTENTFUL_SDK_RETRY_LIMIT,
  )
}

export const CONTENTFUL_CONFIGS: AppConfig['contentfulConfig'] = {
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID ?? '',
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_CDN_KEY ?? '',
  host: process.env.NEXT_PUBLIC_CONTENTFUL_CDN_HOST ?? '',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENV ?? '',
  retryLimit: rateLimitErrorRetryLimit,
  appTagId: process.env.NEXT_PUBLIC_CONTENTFUL_TAG_ID ?? '',
}

export const CONTENTFUL_CONFIGS_PREVIEW: AppConfig['contentfulConfig'] = {
  ...CONTENTFUL_CONFIGS,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_KEY ?? '',
  host: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_HOST ?? '',
}

const buildPreviewContent =
  process.env.BUILD_NEXT_PREVIEW_CONTENT === 'true' &&
  process.env.ENVIRONMENT_NAME !== 'production'

export const NEXT_CONFIGS: AppConfig['nextConfig'] = {
  revalidateTimeErrorPages: buildPreviewContent
    ? 1
    : parseInt(process.env.NEXT_REVALIDATE_TIME_ERROR_PAGES ?? '') || 5,
  revalidateTime: buildPreviewContent
    ? 1
    : parseInt(process.env.NEXT_REVALIDATE_TIME ?? '') || 60,
  buildStatics: process.env.BUILD_NEXT_STATICS === 'true',
  buildPreviewContent,
  basePath: BASE_PATH,
}

export const APPLICATION_SETTINGS_EXTRA_PROPS = {
  customLogoHeight: 38,
  showCDXHeader: true,
}
