export function getAnalyticsTarget(event: MouseEvent) {
  if (!event.target) return null

  const eventTarget = event.target as HTMLElement

  const analyticsTarget =
    eventTarget.tagName === 'A' ||
    eventTarget.tagName === 'BUTTON' ||
    eventTarget.tagName === 'INPUT'
      ? eventTarget
      : eventTarget.closest('A') || eventTarget.closest('BUTTON')
  return analyticsTarget as HTMLElement | null
}
