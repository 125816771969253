import { useMemo } from 'react'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'

import { DEPLOYMENT_TARGET_NAME } from ':cbam/constants'
import {
  LinkLocalizationContext,
  SharedComponentsProvider,
} from '@cb/components'
import {
  CookieBanner,
  Provider as CookieManagerProvider,
  cookieManagerConfig,
} from '@cb/cookie-manager'
import { useAnalytics } from '@cbhq/cdx-analytics'
import {
  CSSReset,
  ComponentsProvider,
  ErrorBoundaryProvider,
  NAVBAR_BANNER_HEIGHT,
  NAVBAR_NAV_HEIGHT,
  notifyError,
} from '@cbhq/cdx-components'
import { Image, Link } from '@cbhq/cdx-next'
import {
  PreviewDisplay,
  SEO,
  useInterceptNextDataHref,
} from '@cbhq/cdx-next-contentful'
import type {
  AppComponentProps,
  ContentfulAppProps,
} from '@cbhq/cdx-next-contentful'
import { logEvent } from '@cbhq/client-analytics'
import {
  DEFAULT_LOGGED_OUT_CONFIG,
  getLoggedOutFallbackLocales,
} from '@cbhq/intl'

import { BugsnagErrorBoundary } from './BugsnagErrorBoundary'

export const App = ({
  Component,
  pageProps,
  seoTitleSuffix,
  router,
}: AppProps<ContentfulAppProps> & AppComponentProps) => {
  const nextRouter = useRouter()
  const { locale = 'en' } = nextRouter
  const pathname = nextRouter.asPath.split('?')[0]
  const pathnameWithLocale = `/${locale}${pathname}`

  const { canonicalUrl, siteConfig } = pageProps?.extraProps || {}
  const { translationMessages } = pageProps

  const bannerHeight =
    siteConfig?.fields.bannerText ||
    pageProps?.page?.content.fields.projectSettings?.fields.bannerText
      ? NAVBAR_BANNER_HEIGHT
      : 0
  const navbarHeight = siteConfig?.fields.navbar ? NAVBAR_NAV_HEIGHT : 0

  useAnalytics({
    clientAnalyticsConfig: {
      projectName: process.env.NEXT_PUBLIC_PROJECT_NAME,
      nextJsRouter: nextRouter,
      isProd: process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production',
      amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    },
  })

  useInterceptNextDataHref({
    router,
  })

  const fallbackLoggedOutConfig = useMemo(
    () => getLoggedOutFallbackLocales(),
    [],
  )

  return (
    <BugsnagErrorBoundary>
      <ErrorBoundaryProvider errorBoundaryComponent={BugsnagErrorBoundary}>
        <ComponentsProvider
          logger={{}}
          locale={locale}
          linkComponent={Link}
          imageComponent={Image}
          canonicalUrl={canonicalUrl}
          localeFromUrl={locale}
          messages={translationMessages || {}}
          anchorsPosition={-(navbarHeight + bannerHeight)}
          defaultLoggedOutConfig={DEFAULT_LOGGED_OUT_CONFIG}
          fallbackLoggedOutConfig={fallbackLoggedOutConfig}
          deploymentTargetName={DEPLOYMENT_TARGET_NAME}
        >
          <SEO
            page={pageProps?.page}
            seoDefaultProps={pageProps?.seoDefaultProps}
            titleSuffix={seoTitleSuffix}
          />

          <CSSReset />

          <PreviewDisplay {...pageProps?.previewData} />
          <SharedComponentsProvider pathname={pathnameWithLocale}>
            <LinkLocalizationContext.Provider
              value={{
                localize: () => locale,
                hideLocaleSelector: true,
              }}
            >
              <CookieManagerProvider
                onError={(e) =>
                  notifyError('CookieManagerProvider Error', e?.message)
                }
                log={(event, options) => logEvent(event, options as any)}
                locale={locale}
                region="DEFAULT"
                projectName={
                  process.env.NEXT_PUBLIC_PROJECT_NAME || 'consumer-marketing'
                }
                config={cookieManagerConfig}
                initialValues={{}}
              >
                <Component {...pageProps} />
                <CookieBanner />
              </CookieManagerProvider>
            </LinkLocalizationContext.Provider>
          </SharedComponentsProvider>
        </ComponentsProvider>
      </ErrorBoundaryProvider>
    </BugsnagErrorBoundary>
  )
}
