import { AnalyticsEventImportance, logEvent } from '@cbhq/client-analytics'

import { CLIENT_ANALYTICS_EVENTS_MAP } from './constants'
import { getAnalyticsTarget } from './getAnalyticsTarget'

export function trackClicks({
  getProps,
  shouldTrackClientAnalytics,
}: {
  shouldTrackClientAnalytics?: boolean
  getProps: () => Record<string, any>
}) {
  /* 
  TODO: look into what getProps returns and ensure there is nothing of value we want to pass along to improve analytics
  */
  const handleClick = (event: MouseEvent) => {
    const analyticsTarget = getAnalyticsTarget(event)
    if (!analyticsTarget) return

    const innerText = analyticsTarget?.innerText
    // client analytics event call
    if (
      shouldTrackClientAnalytics &&
      analyticsTarget.tagName in CLIENT_ANALYTICS_EVENTS_MAP
    ) {
      logEvent(
        'cms_click',
        {
          ...CLIENT_ANALYTICS_EVENTS_MAP[analyticsTarget.tagName],
          innerText,
        },
        // Click events on marketing pages typically redirect,
        // so we want to bypass event batching to send this log ASAP
        AnalyticsEventImportance.high,
      )
    }

    // form submitted
    if (
      (analyticsTarget.tagName === 'BUTTON' ||
        analyticsTarget.tagName === 'INPUT') &&
      analyticsTarget.getAttribute('type') === 'submit'
    )
      return

    // cta clicked (non-navigation)
    if (analyticsTarget.tagName === 'BUTTON') return
  }
  document.addEventListener('click', handleClick)
  return () => document.removeEventListener('click', handleClick)
}
