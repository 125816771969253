import { useEffect } from 'react'

import { initTracking } from './initTracking'
import type { InitConfig } from './types'

export function useAnalytics(props: InitConfig) {
  useEffect(() => {
    const cleanup = initTracking(props)
    return () => cleanup()
  }, [props])
}
