import type { NextWebVitalsMetric } from 'next/app'

import { cdxAnalyticsEventTarget } from './cdxAnalyticsEventTarget'
import { getNextjsMetricName } from './getNextjsMetricName'
import type { CDXAnalyticsLoadedEventDetail } from './types'

export const reportWebVitals = (() => {
  const webVitals: NextWebVitalsMetric[] = []
  let clientAnalytics: CDXAnalyticsLoadedEventDetail

  const logMetric = (metric: NextWebVitalsMetric) => {
    const metricName = getNextjsMetricName(metric)
    if (!metricName) return
    clientAnalytics.logMetric({
      metricName,
      metricType: clientAnalytics.MetricType.distribution,
      value: metric.value,
    })
  }

  cdxAnalyticsEventTarget.addEventListener(
    'client-analytics-loaded',
    (event: Event) => {
      const customEvent = event as CustomEvent<CDXAnalyticsLoadedEventDetail>
      clientAnalytics = customEvent.detail
      webVitals.forEach((metric) => logMetric(metric))
    },
  )

  return (metric: NextWebVitalsMetric) => {
    if (clientAnalytics) logMetric(metric)
    else webVitals.push(metric)
  }
})()
