import { createContext, useContext } from 'react'

import { logInfo, logError, notifyError, logDebug } from '../utils/logs'

type LoggerContextData = {
  logInfo: (message: string, context?: string) => void
  logDebug: (message: string, context?: string) => void
  logError: (message: string, error?: any, context?: string) => void
  notifyError: (message: string, context?: string) => void
}

const defaultProps = {
  logInfo,
  logDebug,
  logError,
  notifyError,
}

const LoggerContext = createContext<LoggerContextData>(defaultProps)

export type LoggerProviderProps = {
  children: React.ReactNode
  logger?: Partial<LoggerContextData>
}

export const LoggerProvider = ({ children, logger }: LoggerProviderProps) => (
  <LoggerContext.Provider
    value={{
      logInfo: logger?.logInfo || defaultProps.logInfo,
      logDebug: logger?.logDebug || defaultProps.logDebug,
      logError: logger?.logError || defaultProps.logError,
      notifyError: logger?.notifyError || defaultProps.notifyError,
    }}
  >
    {children}
  </LoggerContext.Provider>
)

export const useLogger = () => useContext(LoggerContext)
