import { useState, useEffect } from 'react'

import { COINBASE_DOMAIN } from '../../constants'
import { useLogger } from '../../providers/LoggerProvider'
import { cleanUrlDuplicateSlashes } from '../../utils/cleanUrlDuplicateSlashes'
import { getIsAnchorLink } from '../../utils/getIsAnchorLink'
import { getIsRelativeUrl } from '../../utils/getIsRelativeUrl'
import { getUrlWithProtocol } from '../../utils/getUrlWithProtocol'

// TODO: Replace with Array.prototype.findLastIndex after upgrading to TypeScript 5+
const findLastIndex = (array: string[], matchFn: (char: string) => boolean) => {
  const temp = array.slice()
  const index = temp.reverse().findIndex(matchFn)
  return index > -1 ? array.length - 1 - index : index
}

const getBaseDomain = (hostname: string) => {
  const parts = hostname.split('.')
  const lastTLDIndex = findLastIndex(parts, (p: string) => p.length > 3)
  return parts.slice(Math.max(0, lastTLDIndex)).join('.')
}

export const useIsExternalUrl = (url: string) => {
  const [hostname, setHostname] = useState('')
  const { logInfo } = useLogger()

  useEffect(() => setHostname(window.location.hostname), [])

  if (!url || getIsRelativeUrl(url) || getIsAnchorLink(url)) return false

  const cleanUrl = cleanUrlDuplicateSlashes(url)

  if (!hostname) return true

  try {
    const urlWithProtocol = getUrlWithProtocol(cleanUrl)
    const urlHostname = new URL(urlWithProtocol).hostname
    const urlBaseDomain = getBaseDomain(urlHostname)

    return (
      urlBaseDomain !== getBaseDomain(hostname) &&
      urlBaseDomain !== COINBASE_DOMAIN
    )
  } catch (error) {
    logInfo(`Error validating external URL: ${url}`, (error as Error).message)
    return true
  }
}
