import { createContext, useContext } from 'react'

import type { ResponsiveValue } from '../ui/Div/types'

type SectionSpacingContextData = {
  horizontalSpacing: ResponsiveValue<number>
  spacingVerticalAround: ResponsiveValue<number>
  spacingBetweenSections: ResponsiveValue<number>
  anchorsPosition: number
}

const defaultProps = {
  horizontalSpacing: { sm: 3, md: 6, lg: 11 },
  spacingVerticalAround: { sm: 4, md: 7 },
  spacingBetweenSections: { sm: 7, md: 9, lg: 15 },
  anchorsPosition: -90,
}

const SectionSpacingContext =
  createContext<SectionSpacingContextData>(defaultProps)

export type SectionSpacingProviderProps = {
  children: React.ReactNode
} & Partial<SectionSpacingContextData>

export const SectionSpacingProvider = ({
  children,
  horizontalSpacing = defaultProps.horizontalSpacing,
  spacingVerticalAround = defaultProps.spacingVerticalAround,
  spacingBetweenSections = defaultProps.spacingBetweenSections,
  anchorsPosition = defaultProps.anchorsPosition,
}: SectionSpacingProviderProps) => (
  <SectionSpacingContext.Provider
    value={{
      horizontalSpacing,
      spacingVerticalAround,
      spacingBetweenSections,
      anchorsPosition,
    }}
  >
    {children}
  </SectionSpacingContext.Provider>
)

export const useSectionSpacingContext = () => useContext(SectionSpacingContext)
