import React from 'react'

import type { Client } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import type { DefaultErrorBoundaryProps } from '@cbhq/cdx-components'
import { logError } from '@cbhq/cdx-components'

const apiKey =
  process.env.BUGSNAG_API_KEY || process.env.NEXT_PUBLIC_BUGSNAG_API_KEY

const environmentName =
  process.env.ENVIRONMENT_NAME || process.env.NEXT_PUBLIC_ENVIRONMENT_NAME

const codeflowCommitTag =
  process.env.CODEFLOW_COMMIT_TAG || process.env.NEXT_PUBLIC_CODEFLOW_COMMIT_TAG

const appVersion = codeflowCommitTag?.split('-')[1] || 'unknown'

if (!apiKey) {
  logError('Failed to determine Bugsnag apiKey')
}

if (!environmentName) {
  logError('Failed to determine Bugsnag releaseStage')
}

if (!appVersion && environmentName === 'production') {
  logError('Failed to determine Bugsnag appVersion')
}

export let BugsnagInstance: Client | undefined

if (apiKey && environmentName && !BugsnagInstance) {
  // https://docs.bugsnag.com/platforms/javascript/configuration-options/
  BugsnagInstance = Bugsnag.start({
    apiKey,
    appVersion,
    plugins: [new BugsnagPluginReact()],
    // logger: null,
    endpoints: {
      notify: 'https://exceptions.coinbase.com',
      sessions: 'https://sessions.coinbase.com',
    },
    releaseStage: environmentName,
    enabledReleaseStages: ['development', 'production'],
    // enabledBreadcrumbTypes: ['error', 'navigation', 'request', 'user'],
    onBreadcrumb: (breadcrumb) => void {},
    onError: (event) => void {},
  })
}

let ErrorBoundary =
  BugsnagInstance?.getPlugin('react')?.createErrorBoundary(React)

if (!ErrorBoundary) {
  logError('Failed to initialize Bugsnag ErrorBoundary')
}

export const BugsnagErrorBoundary = ({
  fallback = null,
  onError, // TO DO: hook this up
  children,
}: DefaultErrorBoundaryProps) => {
  if (!ErrorBoundary) return <>{fallback}</>
  return (
    <ErrorBoundary FallbackComponent={() => <>{fallback}</>}>
      {children}
    </ErrorBoundary>
  )
}
