import { getAllCookies } from './getAllCookies'
import type { AnalyticsProps } from './types'

/** Gathers props (which cannot be derived from URL) from cookies  */
export const getAnalyticsDefaultProps = (): AnalyticsProps => {
  const cookies = getAllCookies()
  const consent = cookies?.cm_default_preferences?.consent ?? []
  const utms = cookies?.utm ?? {}

  return {
    consent_functional: consent.includes('functional'),
    consent_necessary: consent.includes('necessary'),
    consent_targeting: consent.includes('targeting'),
    consent_performance: consent.includes('performance'),
    consent_deleteifseen: consent.includes('delete-if-seen'),
    ...utms,
  }
}
