import Head from 'next/head'
import { useRouter } from 'next/router'

import { useCanonicalUrl, notifyError } from '@cbhq/cdx-components'

import supportedLocales from '../config/locales/supportedLocales'

export type SEOProps = {
  title?: string
  description?: string
  imageUrl?: string
  noFollow?: boolean
  noIndex?: boolean
  twitterCard?: string
  googleSiteVerification?: string
}

export const SEO = ({
  title,
  description,
  imageUrl,
  noFollow,
  noIndex,
  twitterCard,
  googleSiteVerification,
}: SEOProps) => {
  const { basePath } = useRouter()
  const canonicalUrl = useCanonicalUrl()

  return (
    <Head>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" name="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {canonicalUrl &&
        supportedLocales.length &&
        supportedLocales.map((locale) => {
          return locale !== 'en' ? (
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={appendLocale(canonicalUrl, locale)}
            />
          ) : null
        })}
      {canonicalUrl && (
        <link rel="alternate" hrefLang="x-default" href={canonicalUrl} />
      )}

      {canonicalUrl && (
        <meta property="og:url" name="og:url" content={canonicalUrl} />
      )}

      {description && <meta name="description" content={description} />}
      {description && (
        <meta
          property="og:description"
          name="og:description"
          content={description}
        />
      )}
      {description && <meta name="twitter:description" content={description} />}

      {imageUrl && (
        <meta property="og:image" name="og:image" content={imageUrl} />
      )}
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}

      {twitterCard && (
        <meta
          name="twitter:card"
          content={twitterCard || 'summary_large_image'}
        />
      )}

      <meta
        name="robots"
        content={`${noIndex ? 'noindex' : 'index'}, ${
          noFollow ? 'nofollow' : 'follow'
        }`}
      />

      <meta charSet="UTF-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta property="og:type" content="website" />

      {googleSiteVerification && (
        <meta
          name="google-site-verification"
          content={googleSiteVerification}
        />
      )}

      <link rel="icon" href={`${basePath}/favicon.ico`} />
    </Head>
  )
}

function appendLocale(originalUrl: string, locale: string): string {
  try {
    const url = new URL(originalUrl)
    if (url.pathname && url.pathname !== '/') {
      url.pathname = `/${locale}${url.pathname}`
    } else {
      url.pathname = `/${locale}`
    }
    return url.href
  } catch (error) {
    notifyError('Invalid URL provided:', (error as Error).message)
    return ''
  }
}
