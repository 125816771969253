function formatLogMessage(level, message, context, error) {
  const logMessage = {
    level,
    message,
    timestamp: new Date().toISOString(),
  }

  if (context) {
    logMessage.context = context
  }

  if (error) {
    logMessage.error = error.stack || error.toString()
  }

  return logMessage
}

function logDebug(message, context) {
  console.debug(JSON.stringify(formatLogMessage('debug', message, context)))
}

function logInfo(message, context) {
  console.log(JSON.stringify(formatLogMessage('info', message, context)))
}

function notifyError(message, context) {
  console.warn(JSON.stringify(formatLogMessage('warn', message, context)))
}

function logError(message, error, context) {
  console.error(
    JSON.stringify(formatLogMessage('error', message, context, error)),
  )
}

module.exports = {
  logDebug,
  logInfo,
  notifyError,
  logError,
}
