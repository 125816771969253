import type { logEvent } from '@cbhq/client-analytics'
import { ActionType, ComponentType } from '@cbhq/client-analytics'

export const LOGGING_IDS = {
  link: '28fa896e-0f6d-496b-902a-23572e576a13',
  button: '8c4562a1-7a2d-4d72-8e9e-ba468aa2c0cf',
}

// TO DO: this should be configurable
export const RESOURCE_REDIRECT_PATH = '/institutional/resources/'

export const CLIENT_ANALYTICS_EVENTS_MAP: {
  [key: HTMLElement['tagName']]: Parameters<typeof logEvent>[1]
} = {
  A: {
    loggingId: LOGGING_IDS.link,
    componentType: ComponentType.link,
    action: ActionType.click,
  },
  BUTTON: {
    loggingId: LOGGING_IDS.button,
    componentType: ComponentType.button,
    action: ActionType.click,
  },
}
