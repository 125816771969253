import styled from 'styled-components'

import { createStyleParser } from '../../ui/Div'
import type { DivProps } from '../../ui/Div'
import type { ResponsiveValue, StyleFunction } from '../../ui/Div/types'

export type TextVariant =
  | 'display1'
  | 'display2'
  | 'display3'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'title4'
  | 'headline'
  | 'body'
  | 'label1'
  | 'label2'
  | 'caption'
  | 'legal'

type ResponsiveTextStyleProps = {
  variant?: ResponsiveValue<TextVariant>
}

const textStylePropFunctions: {
  [key in keyof ResponsiveTextStyleProps]: StyleFunction<
    ResponsiveTextStyleProps[key]
  >
} = {
  variant: (v) => ({
    fontSize: `var(--${v}-font-size)`,
    lineHeight: `var(--${v}-line-height)`,
    fontWeight: `var(--${v}-font-weight)`,
    fontFamily: `var(--${v}-font-family)`,
  }),
}

export type TextProps = DivProps & ResponsiveTextStyleProps

const styleFunction = createStyleParser(textStylePropFunctions)

export const Text = styled.span<ResponsiveTextStyleProps>((props) => {
  const styles = styleFunction(props) as any
  if (!Boolean(styles.display)) styles.display = 'inline-flex'
  // TODO: reset CSS
  styles.margin = 0

  return styles
}) as React.FunctionComponent<React.PropsWithChildren<TextProps>>
