import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

// Cookie name for the device id
const COOKIE_DEVICE_ID = 'coinbase_device_id'
// https://www.wolframalpha.com/input/?i=630720000000+milliseconds
const TWENTY_YEARS_AS_MS = 630_720_000_000

export const getCoinbaseDeviceId = () => {
  let id = Cookies.get(COOKIE_DEVICE_ID)
  if (!id && uuidv4) {
    id = uuidv4()
    Cookies.set(COOKIE_DEVICE_ID, id, {
      expires: new Date(Date.now() + TWENTY_YEARS_AS_MS),
    })
  }
  return id
}
