import { useEffect } from 'react'

import type { Entry } from 'contentful'
import type { Router } from 'next/router'

import type { ExperimentsResponse } from '@cbhq/cdx-components'
import type {
  CDXSettingsProjectFields,
  CDXSettingsSiteFields,
  ComposePageFields,
} from '@cbhq/cdx-contentful'
import type { SEOProps } from '@cbhq/cdx-next'

import type { PreviewDisplayProps } from '../types'
import type { TranslationMessages } from '../utils/fetchTranslations'

type DefaultExtraProps = {
  canonicalUrl?: string
  siteConfig?: Entry<CDXSettingsSiteFields>
}

export type DefaultTemplateFields = {
  projectSettings: Entry<CDXSettingsProjectFields>
}

// TODO: DefaultTemplateFields break as some templates are using projectConfig instead of projectSettings
export type ContentfulAppProps<
  // TemplateType extends Record<string, any> & DefaultTemplateFields = Record<
  TemplateType extends Record<string, any> = Record<string, any> &
    DefaultTemplateFields,
  ExtraPropsType extends Record<string, any> = Record<string, any>,
> = {
  page: ComposePageFields<TemplateType & DefaultTemplateFields>
  translationMessages: TranslationMessages | null
  extraProps: ExtraPropsType & DefaultExtraProps
  experimentData: {
    experiments: ExperimentsResponse
    coinbaseDeviceId: string
  }
  previewData?: PreviewDisplayProps | null
  seoDefaultProps?: SEOProps
}

export type AppComponentProps = {
  seoTitleSuffix?: string
}

export const useInterceptNextDataHref = ({ router }: { router: Router }) => {
  useEffect(() => {
    if (router.pageLoader?.getDataHref) {
      const originalGetDataHref = router.pageLoader.getDataHref
      router.pageLoader.getDataHref = function (...args: [any]) {
        const r = originalGetDataHref.call(router.pageLoader, ...args)
        return r && r.startsWith('/_next/data')
          ? `${process.env.NEXT_PUBLIC_PROJECT_BASE_PATH}${r}`
          : r
      }
    }
  }, [router])
}
