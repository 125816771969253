import { useState } from 'react'

import type { ImageComponent } from '@cbhq/cdx-components'
import { Div, SkeletonLoader, getUrlWithProtocol } from '@cbhq/cdx-components'

import {
  CONTENTFUL_COINBASE_IMAGES_CDN,
  CONTENTFUL_IMAGES_DOMAIN,
} from './constants'

export const Image: ImageComponent = ({
  src,
  alt,
  loading = 'lazy',
  // TODO: check if we can use Next's fill instead.
  width = 1000,
  height = 1000,
  className,
  priority,
}) => {
  const srcString = typeof src === 'object' ? src.src : src

  const srcWithProtocol = getUrlWithProtocol(srcString)

  const cdnSrc = srcWithProtocol.replace(
    CONTENTFUL_IMAGES_DOMAIN,
    CONTENTFUL_COINBASE_IMAGES_CDN,
  )

  const isCdnImage = cdnSrc.includes(CONTENTFUL_COINBASE_IMAGES_CDN)

  const [hasPlaceholder, setHasPlaceholder] = useState(false)

  return (
    <Div
      position="relative"
      width="100%"
      height="100%"
      css={{ img: { zIndex: 0 } }}
    >
      {hasPlaceholder && <SkeletonLoader />}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={cdnSrc}
        alt={alt || ''}
        loading={loading}
        sizes="100vw"
        width={width}
        height={height}
        className={className}
      />
    </Div>
  )
}
