import type { ContentfulClientApi, CreateClientParams, Entry } from 'contentful'
import { createClient } from 'contentful'
import { logError } from '@cbhq/cdx-components'

export type GetContentEntryParams = {
  isPreview?: boolean | null
  env?: string
  locale?: string

  slug?: string
  contentType?: string
  queryParams?: {
    [key: string]: string | number | undefined
    limit?: number
    skip?: number
  }
  tagId?: string
}

let previewClients: {
  [envName: string]: {
    client: ContentfulClientApi
    params: CreateClientParams
  }
} = {}

let defaultClient: ContentfulClientApi

export const initContentfulClient = ({
  config,
  previewConfig,
}: {
  config: CreateClientParams
  previewConfig?: CreateClientParams
}): void => {
  defaultClient = createClient(config)

  if (previewConfig && previewConfig.accessToken) {
    const key = previewConfig.environment ?? 'master'
    previewClients[key] = {
      client: createClient(previewConfig),
      params: previewConfig,
    }

    if (!previewClients.default) {
      previewClients.default = previewClients[key]
    }
  }
}

export const getContentEntry = async <T>(
  params: GetContentEntryParams,
  shouldThrowError?: boolean,
): Promise<Entry<T> | null> => {
  const results = (await getContentEntries<T>(
    {
      ...params,
      queryParams: { ...params.queryParams, limit: 1 },
    },
    shouldThrowError,
  )) as unknown as { items: Entry<T>[]; total: number }

  return results && results?.items ? results.items[0] : null
}

export const getContentEntries = async <T>(
  {
    isPreview,
    env = 'default',
    locale,

    tagId,
    slug,
    contentType = 'page',
    queryParams = {},
  }: GetContentEntryParams,
  shouldThrowError?: boolean,
): Promise<{
  items: Entry<T>[]
  total: number
  skip: number
} | null> => {
  let client = defaultClient

  if (isPreview) {
    client = previewClients[env]?.client

    if (!client && previewClients.default) {
      initContentfulClient({
        config: {
          ...previewClients.default.params,
          environment: env,
        },
      })

      client = previewClients[env].client
    }
  }

  try {
    const query = {
      include: 10,
      locale,
      content_type: contentType,
      ...(slug && { 'fields.slug': slug }),
      ...(tagId && { 'metadata.tags.sys.id[all]': tagId }),
      ...queryParams,
    }

    const result = await client.getEntries<T>(query)

    if (result.items.length > 0) {
      // see: https://jira.coinbase-corp.com/browse/GROW-7619
      // and: https://github.com/contentful/contentful.js/issues/377
      const { items, total, skip } = JSON.parse(
        result.stringifySafe(),
      ) as typeof result

      return { items, total, skip }
    }

    return null
  } catch (e) {
    if (shouldThrowError) throw e

    logError('error', e)
    return null
  }
}

export const getAllContentEntries = async <T>(
  params: GetContentEntryParams,
  shouldThrowError?: boolean,
): Promise<{
  items: Entry<T>[]
  total: number
} | null> => {
  const limit = params?.queryParams?.limit || 100
  let skip = 0
  let total = 1

  let items: Entry<T>[] = []

  while (skip < total) {
    const query = {
      ...params,
      queryParams: {
        ...params.queryParams,
        limit,
        skip,
      },
    }

    const entries = await getContentEntries<T>(query, shouldThrowError)

    if (!entries) break

    total = entries.total
    skip = entries.skip + limit
    items = items.concat(entries.items)
  }

  if (!items.length) return null

  return { items, total }
}
