import { createContext, useContext } from 'react'

import type { ImageComponent } from '../ui/Image/ImageBase'

export type ImageComponentProviderProps = {
  children: React.ReactNode
  imageComponent?: ImageComponent
}

const DefaultImageComponent: ImageComponent = ({
  src,
  alt,
  width,
  height,
  className,
  loading = 'lazy',
}) => {
  const isStaticallyImported = typeof src === 'object'
  const imgWidth = width || (isStaticallyImported ? src.width : undefined)
  const imgHeight = height || (isStaticallyImported ? src.height : undefined)

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={typeof src === 'object' ? src.src : src}
      alt={alt || ''}
      width={imgWidth}
      height={imgHeight}
      loading={loading}
      className={className}
    />
  )
}

const ImageComponentContext = createContext<ImageComponent>(
  DefaultImageComponent,
)

export const ImageComponentProvider = ({
  children,
  imageComponent = DefaultImageComponent,
}: ImageComponentProviderProps) => (
  <ImageComponentContext.Provider value={imageComponent}>
    {children}
  </ImageComponentContext.Provider>
)

export const useImageComponent = () => useContext(ImageComponentContext)
